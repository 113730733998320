<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details">
                  <b-col lg="12" sm="12">
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.drilling_log') }}</h5>
                          <b-table striped small bordered hover :items="location_columns"  thead-class="hidden_header" id="mysection"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.operation') }}</h5>
                          <b-table striped small bordered hover :items="operation_columns"  thead-class="hidden_header">
                            <template v-slot:cell(drilling_start_time)="data">
                              <span>{{ data.drilling_start_time | dateFormatTime }}</span>
                            </template>
                            <template v-slot:cell(drilling_complete_time)="data">
                              <span>{{ data.drilling_complete_time | dateFormatTime }}</span>
                            </template>
                            <template v-slot:cell(casing_start_time)="data">
                              <span>{{ data.casing_start_time | dateFormatTime }}</span>
                            </template>
                            <template v-slot:cell(casing_complete_time)="data">
                              <span>{{ data.casing_complete_time | dateFormatTime }}</span>
                            </template>
                            <template v-slot:cell(gravel_placing_start_time)="data">
                              <span>{{ data.gravel_placing_start_time | dateFormatTime }}</span>
                            </template>
                            <template v-slot:cell(gravel_placing_complete_time)="data">
                              <span>{{ data.gravel_placing_complete_time | dateFormatTime }}</span>
                            </template>
                          </b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.housing_pipe_dia') }}</h5>
                          <b-table striped small bordered hover :items="housing_pipe_dia_columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.LWC') }}</h5>
                          <h6 class="font-weight-bold text-capitalize mt-2">{{ $t('irriPumpInformation.depth_below_GL') }}</h6>
                          <b-table striped small bordered hover :items="lwc_columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.screen_dia') }}</h5>
                          <h6 class="font-weight-bold text-capitalize mt-2">{{ $t('irriPumpInformation.depth_below_GL') }}</h6>
                          <b-table striped small bordered hover :items="screen_dia_columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.bail_plug') }}</h5>
                          <h6 class="font-weight-bold text-capitalize mt-2">{{ $t('irriPumpInformation.depth_below_GL') }}</h6>
                          <b-table striped small bordered hover :items="bail_plug_columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.centralizes') }}</h5>
                          <b-table striped small bordered hover :items="centralizes_columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.gravel') }}</h5>
                          <h6 class="font-weight-bold text-capitalize mt-2">{{ $t('irriPumpInformation.depth_below_GL') }}</h6>
                          <b-table striped small bordered hover :items="gravel_columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center bg-light p-1">{{ $t('irriPumpInformation.verticality_test') }}</h5>
                          <b-table striped small bordered hover :items="verticality_test_columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.constructionDetails = this.item
  },
  data () {
    return {
      constructionDetails: '',
      slOffset: 1
    }
  },
  computed: {
    location_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.project'), val: this.constructionDetails.project_name_bn, key1: this.$t('irriPumpInformation.pump_id'), val1: this.$n(this.constructionDetails.pump_id) },
          { key: this.$t('complain.division'), val: this.constructionDetails.division_name_bn, key1: this.$t('complain.district'), val1: this.constructionDetails.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.constructionDetails.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.constructionDetails.union_name_bn },
          { key: this.$t('irriPumpInformation.mouza_no'), val: this.$n(this.constructionDetails.mouza_no, { useGrouping: false }), key1: this.$t('irriPumpInformation.jl_no'), val1: this.$n(this.constructionDetails.jl_no, { useGrouping: false }) },
          { key: this.$t('irriPumpInformation.plot_no'), val: this.$n(this.constructionDetails.plot_no, { useGrouping: false }), key1: this.$t('irriPumpInformation.well_no'), val1: this.constructionDetails.well_no },
          { key: this.$t('irriPumpInformation.engineer_name'), val: this.constructionDetails.engineer_name_bn, key1: this.$t('irriPumpInformation.drilling_contractor_name'), val1: this.constructionDetails.drilling_contractor_name_bn }
        ]
      } else {
        return [
          { key: this.$t('irriPumpInformation.project'), val: this.constructionDetails.project_name, key1: this.$t('irriPumpInformation.pump_id'), val1: this.constructionDetails.pump_id },
          { key: this.$t('complain.division'), val: this.constructionDetails.division_name, key1: this.$t('complain.district'), val1: this.constructionDetails.district_name },
          { key: this.$t('complain.upazila'), val: this.constructionDetails.upazilla_name, key1: this.$t('complain.union'), val1: this.constructionDetails.union_name },
          { key: this.$t('irriPumpInformation.mouza_no'), val: this.constructionDetails.mouza_no, key1: this.$t('irriPumpInformation.jl_no'), val1: this.constructionDetails.jl_no },
          { key: this.$t('irriPumpInformation.plot_no'), val: this.constructionDetails.plot_no, key1: this.$t('irriPumpInformation.well_no'), val1: this.constructionDetails.well_no },
          { key: this.$t('irriPumpInformation.engineer_name'), val: this.constructionDetails.engineer_name, key1: this.$t('irriPumpInformation.drilling_contractor_name'), val1: this.constructionDetails.drilling_contractor_name }
        ]
      }
    },
    operation_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.drilling_started'), val: this.constructionDetails.drilling_start_time, key1: this.$t('irriPumpInformation.drilling_completed'), val1: this.constructionDetails.drilling_complete_time, key2: this.$t('irriPumpInformation.casing_started'), val2: this.constructionDetails.casing_start_time, key3: this.$t('irriPumpInformation.casing_completed'), val3: this.constructionDetails.casing_complete_time },
          { key: this.$t('irriPumpInformation.gravel_placing_started'), val: this.constructionDetails.gravel_placing_start_time, key1: this.$t('irriPumpInformation.casing_started'), val1: this.constructionDetails.gravel_placing_complete_time, key2: this.$t('irriPumpInformation.drilling_diameter'), val2: this.$n(this.constructionDetails.drilling_diameter) }
        ]
      } else {
        return [
          { key: this.$t('irriPumpInformation.drilling_started'), val: this.constructionDetails.drilling_start_time, key1: this.$t('irriPumpInformation.drilling_completed'), val1: this.constructionDetails.drilling_complete_time, key2: this.$t('irriPumpInformation.casing_started'), val2: this.constructionDetails.casing_start_time, key3: this.$t('irriPumpInformation.casing_completed'), val3: this.constructionDetails.casing_complete_time },
          { key: this.$t('irriPumpInformation.gravel_placing_started'), val: this.constructionDetails.gravel_placing_start_time, key1: this.$t('irriPumpInformation.gravel_placing_completed'), val1: this.constructionDetails.gravel_placing_complete_time, key2: this.$t('irriPumpInformation.drilling_diameter'), val2: this.$n(this.constructionDetails.drilling_diameter) }
        ]
      }
    },
    housing_pipe_dia_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.UWC_above_GL'), val: this.$n(this.constructionDetails.uwc_above_gl, { useGrouping: false }), key1: this.$t('irriPumpInformation.UWC_below_GL'), val1: this.$n(this.constructionDetails.uwc_below_gl), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.constructionDetails.uwc_total_gl) }
        ]
      } else {
        return [
          { key: this.$t('irriPumpInformation.UWC_above_GL'), val: this.$n(this.constructionDetails.uwc_above_gl, { useGrouping: false }), key1: this.$t('irriPumpInformation.UWC_below_GL'), val1: this.$n(this.constructionDetails.uwc_below_gl), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.constructionDetails.uwc_total_gl) }
        ]
      }
    },
    lwc_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.from'), val: this.$n(this.constructionDetails.lwc_from_gl, { useGrouping: false }), key1: this.$t('irriPumpInformation.to'), val1: this.$n(this.constructionDetails.lwc_to_gl), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.constructionDetails.lwc_total_gl) }
        ]
      } else {
        return [
          { key: this.$t('irriPumpInformation.from'), val: this.$n(this.constructionDetails.lwc_from_gl, { useGrouping: false }), key1: this.$t('irriPumpInformation.to'), val1: this.$n(this.constructionDetails.lwc_to_gl), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.constructionDetails.lwc_total_gl) }
        ]
      }
    },
    screen_dia_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.from'), val: this.$n(this.constructionDetails.screen_from_gl, { useGrouping: false }), key1: this.$t('irriPumpInformation.to'), val1: this.$n(this.constructionDetails.screen_to_gl), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.constructionDetails.screen_total_gl) }
        ]
      } else {
        return [
          { key: this.$t('irriPumpInformation.from'), val: this.$n(this.constructionDetails.screen_from_gl, { useGrouping: false }), key1: this.$t('irriPumpInformation.to'), val1: this.$n(this.constructionDetails.screen_to_gl), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.constructionDetails.screen_total_gl) }
        ]
      }
    },
    bail_plug_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.from'), val: this.$n(this.constructionDetails.bail_plug_from_gl, { useGrouping: false }), key1: this.$t('irriPumpInformation.to'), val1: this.$n(this.constructionDetails.bail_plug_to_gl), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.constructionDetails.bail_plug_total_gl) }
        ]
      } else {
        return [
          { key: this.$t('irriPumpInformation.from'), val: this.$n(this.constructionDetails.bail_plug_from_gl, { useGrouping: false }), key1: this.$t('irriPumpInformation.to'), val1: this.$n(this.constructionDetails.bail_plug_to_gl), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.constructionDetails.bail_plug_total_gl) }
        ]
      }
    },
    centralizes_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.description'), val: this.constructionDetails.centralize_description, key1: this.$t('irriPumpInformation.total'), val1: this.$n(this.constructionDetails.centralize_total) }
        ]
      } else {
        return [
          { key: this.$t('irriPumpInformation.description'), val: this.constructionDetails.centralize_description, key1: this.$t('irriPumpInformation.total'), val1: this.$n(this.constructionDetails.centralize_total) }
        ]
      }
    },
    gravel_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.from'), val: this.$n(this.constructionDetails.gravel_depth_form, { useGrouping: false }), key1: this.$t('irriPumpInformation.to'), val1: this.$n(this.constructionDetails.gravel_depth_to), key2: this.$t('irriPumpInformation.fixture_length'), val2: (this.$n(this.constructionDetails.fixture_length) + ' ' + this.$t('irriPumpInformation.from_up_to_UWC')) }
        ]
      } else {
        return [
          { key: this.$t('irriPumpInformation.from'), val: this.$n(this.constructionDetails.gravel_depth_form, { useGrouping: false }), key1: this.$t('irriPumpInformation.to'), val1: this.$n(this.constructionDetails.gravel_depth_to), key2: this.$t('irriPumpInformation.fixture_length'), val2: (this.$n(this.constructionDetails.fixture_length) + ' ' + this.$t('irriPumpInformation.from_up_to_UWC')) }
        ]
      }
    },
    verticality_test_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.max_deviation'), val: this.$n(this.constructionDetails.max_deviation, { useGrouping: false }), key1: this.$t('irriPumpInformation.inch_at'), val1: (this.$n(this.constructionDetails.max_depth) + ' ' + this.$t('irriPumpInformation.depth')), key2: this.$t('irriPumpInformation.date'), val2: this.$d(new Date(this.constructionDetails.date)) },
          { key: this.$t('irriPumpInformation.cross_section_of_the_well'), val: this.constructionDetails.cross_section_of_well, key1: this.$t('irriPumpInformation.ordered_depth'), val1: this.$n(this.constructionDetails.ordered_depth) }
        ]
      } else {
        return [
          { key: this.$t('irriPumpInformation.max_deviation'), val: this.$n(this.constructionDetails.max_deviation, { useGrouping: false }), key1: this.$t('irriPumpInformation.inch_at'), val1: (this.$n(this.constructionDetails.max_depth) + ' ' + this.$t('irriPumpInformation.depth')), key2: this.$t('irriPumpInformation.date'), val2: this.$d(new Date(this.constructionDetails.date)) },
          { key: this.$t('irriPumpInformation.cross_section_of_the_well'), val: this.constructionDetails.cross_section_of_well, key1: this.$t('irriPumpInformation.ordered_depth'), val1: this.$n(this.constructionDetails.ordered_depth) }
        ]
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
  div.details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  /* .table-bordered td:nth-child(3n+1), [dir=ltr][mode=light] .table-bordered th {
    font-weight: 800;
  } */

</style>
